<template>
  <div>
    <navtop />
    <menuNav />
    <div class="content">
      <el-breadcrumb separator="/" style="padding: 20px 0px">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>充值中心</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="goodsInfo">
        <div class="head">商品信息</div>
        <div class="goodsbox clearfix">
          <!-- 卡片 -->
          <div
            :class="{ backimg: goodsAct == index, outer: true, fl: true }"
            v-for="(item, index) in list"
            :key="index"
            @click="getCards(item, index)"
          >
            <div class="goodsCard">
              <div>{{ item.mm_name }}</div>
              <div style="text-align: right; font-size: 28px">
                ¥{{ item.mm_price }}/月
              </div>
              <div
                style="
                  font-size: 14px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ item.introduce }}
              </div>
            </div>
          </div>
        </div>
        <div class="head">会员有效期限</div>
        <div class="timetab">
          <span
            :class="{ timeAct: timeActive == index }"
            v-for="(item, index) in timelist"
            :key="index"
            @click="getTimes(item, index)"
            >{{ item }}</span
          >
        </div>
        <div
          style="text-align: right; margin-right: 40px; padding-bottom: 20px"
        >
          共计：¥<span style="color: red; font-size: 40px; width: 120px">
            {{ acount }}元</span
          >
        </div>
      </div>
      <div class="checkin">
        <div :class="{ imgcheck: isZhifu == 1 }" @click="getZhifu(1)">
          <img src="../assets/image/weixin.png" alt="" /> 微信支付
        </div>
        <div :class="{ imgcheck: isZhifu == 2 }" @click="getZhifu(2)">
          <img src="../assets/image/zhifubao.png" alt="" />支付宝支付
        </div>
      </div>

      <div style="text-align: center; padding: 30px 0 50px">
        <el-button style="width: 200px" type="primary" round @click="getPayment"
          >立即支付</el-button
        >
      </div>
      <el-dialog
        title="微信支付"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
        width="30%"
      >
        <div
          style="
            height: 200px;
            width: 300px;
            text-align: center;
            margin: 0 auto;
          "
          class="clearfix"
        >
          <div style="margin-bottom: 20px">
            付款金额：<span style="color: red; font-size: 40px"
              >{{ acount }}元</span
            >
          </div>
          <div
            id="qrcode"
            ref="qrcode"
            class="fl"
            style="padding-top: 20px"
          ></div>
          <img
            src="../assets/image/wxpay.png"
            alt=""
            width="100"
            class="fr"
            style="margin-left: 30px"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose">取 消</el-button>
          <el-button type="primary" @click="handleClose">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import URL from "@/api/index";
import QRCode from "qrcodejs2";
import navtop from "@/components/navtop.vue";
import menuNav from "@/components/menuNav.vue";
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          img: "../assets/image/money1.png",
          title: "搜老师优质资源会员",
          money: "¥19.90/月",
          tips: " 免费观看平台所有课程，包含专题课、同步课等。",
        },
      ],
      timelist: ["一个月", "三个月", "半年", "一年"],
      goodsAct: null,
      timeActive: null,
      isZhifu: 0,
      acount: "0.00",
      iscard: 1, //19.9
      card: {},
      timelast: 1,
      dialogVisible: false,
    };
  },
  components: {
    navtop,
    menuNav,
  },
  mounted() {
    var datalist = {
      token: JSON.parse(localStorage.getItem("loginInfo")).token,
      type: 1,
    };
    this.$post(URL.getcardList, datalist).then((res) => {
      this.list = res.result;
      // console.log(res);
    });
  },
  methods: {
    getCards(item, index) {
      this.card = item;
      this.acount = item.mm_price;
      this.goodsAct = index;
      this.timeActive = 0;
      this.timelast = 1;
    },
    getTimes(item, index) {
      if (index == 0) {
        this.timelast = 1;
        this.acount = this.card.mm_price;
      }
      if (index == 1) {
        this.timelast = 3;
        this.acount = this.card.mm_3m_price;
      }
      if (index == 2) {
        this.timelast = 6;
        this.acount = this.card.mm_6m_price;
      }
      if (index == 3) {
        this.timelast = 12;
        this.acount = this.card.mm_year_price;
      }
      if (JSON.stringify(this.card) == "{}") {
        this.acount = "0.00";
      }
      this.timeActive = index;
    },
    getZhifu(index) {
      this.isZhifu = index;
    },
    // getBgimg(item) {
    //   return {
    //     background: "url(" + item.img + ") no-repeat 0px 0px",
    //   };
    // },
    getPayment() {
      if (JSON.stringify(this.card) == "{}") {
        this.$message("请选择商品");
        return false;
      }
      if (this.isZhifu == 0) {
        this.$message("请选择支付方式");
        return false;
      }
      if (this.isZhifu == 1) {
        // 微信支付
        this.dialogVisible = true;
        this.createOrder("scan", "wechat", "wx", this.card);
      } else {
        // 支付宝
        this.createOrder("web", "alipay", "zfb", this.card);
      }
    },
    // 生成订单
    createOrder(s, w, t, orderJson) {
      var datalist = {
        token: JSON.parse(localStorage.getItem("loginInfo")).token,
        order_title: orderJson.mm_name,
        order_type: 3,
        order_coin_type: 3,
        order_coin_amount: this.acount,
        order_busi_type: "vip",
        order_busi_id: orderJson.id,
        duration: this.timelast,
      };
      this.$post(URL.getPayOrder, datalist).then((res) => {
        if (res.state == 0) {
          this.zf(s, w, res.result.order_id, t);
        }
      });
    },
    // 支付
    zf(client, pay_type, e, t) {
      this.isZhifu == 0; //清空选择的支付方式
      var datalist = {
        token: JSON.parse(localStorage.getItem("loginInfo")).token,
        order_id: e,
        client_type: client,
        pay_type: pay_type,
        returnurl:"http://res.slaoshi.com/#/voucherCenter"
          // `http://www.rujiaowang.net/app/member/?t=` +
          // JSON.parse(localStorage.getItem("loginInfo")).token,
      };

      this.$post(URL.getdoPayOrder, datalist).then((res) => {
        if (res.state == 0) {
          // 支付宝 打开连接
          if (t == "zfb") {
            document.write(res.result);
          } else {
            // 微信 生成二维码
            this.qrcodeScan(res.result);
          }
        }
      });
    },
    // 微信支付 生成二维码
    qrcodeScan(res) {
      //生成二维码
      new QRCode("qrcode", {
        width: 100, // 二维码宽度
        height: 100, // 二维码高度
        text: res,
      });
    },
    // 关闭弹框 删除二维码
    handleClose() {
      this.dialogVisible = false;
      this.$refs.qrcode.innerHTML = "";
    },
  },
};
</script>

<style lang='less' scoped>
.content {
  width: 1200px;
  margin: 0 auto;
  .goodsInfo {
    box-shadow: 0 0 10px #e9e9e9;
    .head {
      padding-left: 20px;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      font-weight: 700;
      border-bottom: 1px solid #e9e8e8;
    }
    .timetab {
      padding-left: 20px;
      height: 40px;
      margin-top: 24px;
      span {
        cursor: pointer;
        margin-right: 50px;
        display: inline-block;
        padding: 5px 20px;
        border-radius: 6px;
      }
    }
    .timeAct {
      background-color: #deeafc;
      border: 1px solid #1472fe;
      color: #1472fe;
    }
    .goodsbox {
      padding: 20px 10px;
      .outer {
        cursor: pointer;
        margin: 0 36px;
        margin-bottom: 30px;
        width: 360px;
        height: 180px;
        padding: 42px 72px;
        .goodsCard {
          padding: 40px;
          color: #fff;
          width: 360px;
          height: 180px;
          box-sizing: border-box;
          border-radius: 10px;
          div {
            margin-bottom: 10px;
            font-size: 18px;
          }
        }
      }

      .backimg {
        background: url("../assets/image/outer.png") no-repeat 0px 0px;
      }
    }
    .outer:nth-child(1) .goodsCard {
      background: url("../assets/image/money1.png")
        no-repeat 0px 0px;
    }
    .outer:nth-child(2) .goodsCard {
      background: url("../assets/image/money2.png")
        no-repeat 0px 0px;
    }
    .outer:nth-child(3) .goodsCard {
      background: url("../assets/image/money3.png")
        no-repeat 0px 0px;
    }
  }
}
.checkin {
  margin-top: 30px;
  box-shadow: 0 0 10px #e9e9e9;
  padding: 20px;
  div {
    cursor: pointer;
    display: inline-block;
    margin-right: 50px;
    width: 160px;
    height: 55px;
    font-size: 14px;
    line-height: 55px;
    img {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
  .imgcheck {
    background: url("../assets/image/outer2i.png") no-repeat 0px 0px;
  }
}
</style>
